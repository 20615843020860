/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 *  Unauthorized copying of this file, via any medium is strictly prohibited.
 *  Proprietary and confidential.
 */

import qs from 'qs';

export default {
	name: 'portalMixins',
	
	data: () => ({
		mfaSettings: [
			{
				text: 'Enabled',
				value: true
			},
			{
				text: 'Disabled',
				value: false
			},
			{
				text: 'Account Default',
				value: "default"
			},
		],
		globalLoading: false,
		envSelected: {
			value: 'portal',
			name: 'PORTAL',
			color: {name: 'secondary', code: '#1867c0'}
		}
	}),
	
	components: {},
	
	computed: {
		isAuthenticated() {
			return !!this.$store.getters['auth/getToken'];
		},
		currentUser() {
			return this.$store.getters['auth/getUser'];
		},
		currentToken() {
			return this.$store.getters['auth/getToken'];
		},
		globalMessages() {
			return this.$store.getters['globalMessages/list'];
		}
	},
	
	methods: {
		/* eslint-disable */
		validEmail: function (pass) {
			var re = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
			return re.test(pass);
		},
		/* eslint-enable */
		
		validPassword: function (pass) {
			var re = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\s\*.!@$%\^&(){}\[\]:;<>,.?\/~_+\-=|\\]).{10,32}$/;
			return re.test(pass);
		},
		
		redirectIfAuthenticated: function (destination) {
			this.globalLoading = true;
			if (!destination) {
				destination = {name: "Dashboard", params: {envCode: 'portal'}};
			}
			if (this.isAuthenticated) {
				this.$router.push(destination);
			} else {
				this.globalLoading = false;
			}
		},
		
		returnMenuItemRoute: function (item, $event) {
			if (item.click) {
				return null;
			} else {
			
			}
		},
		
		handleMenuItem: function (item, $event) {
			$event.preventDefault();
			if (item.click) {
				this[item.click]();
			} else {
				this.goToPage(item);
			}
		},
		
		goToPage: function (myRoute) {
			let page = this.getPage(myRoute);
			if (page) {
				this.$router.push(page);
			}
		},
		
		getPage: function (myRoute) {
			let routeData = this.$router.resolve({name: myRoute.route});
			
			if (routeData && this.$router.currentRoute.name !== routeData.route.name) {
				if (this.canAccess(routeData.route.meta.acl)) {
					
					if (!myRoute.params) {
						myRoute.params = {};
					}
					
					return {name: routeData.route.name, params: myRoute.params || {}};
				}
			}
		},
		
		updateMenuEntries: async function (menu) {
			for (let i = menu.length - 1; i >= 0; i--) {
				let envCode = this.envSelected.value.toLowerCase();
				if (menu[i].route) {
					let routeData = this.$router.resolve({name: menu[i].route});
					
					if (routeData && routeData.route) {
						if (routeData.route && routeData.route.meta && routeData.route.meta.acl && routeData.route.meta.acl.envCode) {
							envCode = routeData.route.meta.acl.envCode;
						}
						let acl = await this.$acl.isAuthenticated(routeData.route, envCode);
						if (!acl) {
							menu.splice(i, 1);
						}
					}
				} else if (menu[i].children) {
					menu[i].children = await this.updateMenuEntries(menu[i].children);
					
					if (menu[i].children.length === 0) {
						menu.splice(i, 1);
					}
				}
			}
			
			if (menu && menu[0] && menu[0].divider) {
				menu.shift();
			}
			return menu;
		},
		
		canAccess: function (context) {
			let envCode = (context.envCode) ? context.envCode : this.envSelected.value;
			return this.$acl.hasComponentAccess(context, envCode);
		},
		
		clearMessages() {
			this.$store.dispatch('globalMessages/clear');
		},
		
		pushMessage(context) {
			this.$store.dispatch('globalMessages/push', {
				type: context.type,
				title: context.title,
				text: context.text
			});
			this.scrollToTop();
		},
		
		scrollToTop() {
			window.scrollTo(0, 0);
		},
		
		scrollMeTo(refName) {
			let element = this.$refs[refName];
			if (!element) {
				element = document.getElementById(refName);
			}
			if (element) {
				let top = element.offsetTop;
				window.scrollTo({
					top: top + 150,
					left: 0,
					behavior: 'smooth'
				});
			}
		},
		
		copyCodeToClipboard(divId) {
			let codeToCopy = document.querySelector('#' + divId);
			codeToCopy.setAttribute('type', 'text');   // 不是 hidden 才能複製
			codeToCopy.select();
			
			try {
				const successful = document.execCommand('copy');
				if (!successful) {
					alert('Oops, unable to copy');
				}
			} catch (err) {
				alert('Oops, unable to copy');
			}
			
			/* unselect the range */
			codeToCopy.setAttribute('type', 'hidden');
			window.getSelection().removeAllRanges()
		},
		
		executeRequest(context) {
			const APIHeaders = context.headers || {};
			
			const token = this.$store.getters['auth/getToken'];
			if (token && token.access_token) {
				APIHeaders['Authorization'] = token.access_token;
			}
			
			if (!APIHeaders['env']) {
				APIHeaders['env'] = (this.envSelected) ? this.envSelected.value : 'portal';
			}
			
			const requestOptions = {
				url: context.url,
				method: (context.method || 'GET').toUpperCase(),
				headers: APIHeaders,
			};
			
			if (!['POST', 'PUT', 'PATCH'].includes(requestOptions.method)) {
				requestOptions.params = context.params;
			} else {
				requestOptions.data = context.params;
			}
			
			if (context.blob) {
				requestOptions.responseType = 'blob';
			}
			
			requestOptions.paramsSerializer = function (params) {
				return qs.stringify(params, {arrayFormat: 'repeat'});
			};
			// if handle error is false
			return this.$http(requestOptions)
				.then(response => {
					if (context.blob) {
						return response;
					} else if (!response.data.data) {
						return response.data;
					} else
						return response.data.data;
				}).catch(error => {
					// normalize error
					const errorRes = {};
					if (error.response) {
						if (error.response.status) {
							errorRes.status = error.response.status;
						}
						if (error.response.data) {
							if (error.response.data.errors) {
								errorRes.errors = error.response.data.errors;
							} else if (error.response.data.error) {
								let code;
								if (error.response.data.code) {
									code = error.response.data.code;
								} else {
									code = Object.keys(error.response.data.statusCode)[0] || error.response.status || 600;
								}
								errorRes.errors = {};
								errorRes.errors[code] = {
									code,
									message: error.response.data.message
								};
							}
						}
					}
					if (!errorRes.status) errorRes.status = 500;
					if (!errorRes.errors) errorRes.errors = {
						'500': {
							code: 500,
							message: 'An internal error occurred'
						}
					};
					const code = Object.keys(errorRes.errors)[0] || errorRes.status;
					errorRes.message = errorRes.errors[code].message;
					// throw normalized error
					throw errorRes;
				});
		},
		
		async getSendData(context) {
			const self = this;
			
			function forceLogout(errors) {
				self.$store.dispatch('auth/clear');
				self.$store.dispatch('env/clear');
				self.$cookies.remove('token');
				self.clearMessages();
				
				if (self.$router.currentRoute.name !== 'Login') {
					self.$router.push({name: "Login"});
				}
				
				setTimeout(() => {
					printErrors(errors);
				}, 1000);
			}
			
			function printErrors(errors) {
				for (let code in errors) {
					self.$store.dispatch('globalMessages/push', {
						type: 'error',
						title: `Error ${code}`,
						text: errors[code].message
					});
				}
			}
			
			if (!context.noLoading) {
				self.$emit('triggerOverlayLoading', true);
			}
			self.clearMessages();
			return self.executeRequest(context)
				.then(data => data)
				.catch(error => {
					if (error.status === 520 && error.errors['142']) {
						if (context.url === "/logout") {
							forceLogout(error.errors);
							throw error;
						} else {
							// load existing expired token
							const token = self.$store.getters['auth/getToken'];
							// refresh access token
							if (token && token.refresh_token) {
								return self.executeRequest({
									url: '/token',
									method: 'put',
									params: {
										'refresh': token.refresh_token
									}
								}).then(async dataRes => {
									// save new token
									self.$store.dispatch('auth/setToken', dataRes);
									// retry original request
									return await self.getSendData(context);
								}).catch(error => {
									const token = self.$store.getters['auth/getToken'];
									if (!token || !token.access_token || (error.errors && error.errors['140'] && error.errors['140'].message === 'Invalid Refresh Token Provided!')) {
										forceLogout(error.errors);
									}
									throw error;
								});
							} else {
								forceLogout(error.errors);
								throw error;
							}
						}
					} else {
						self.handleErrorResponse(error);
						throw error;
					}
				}).finally(() => {
					if (!context.noLoading) {
						self.$emit('triggerOverlayLoading', false);
					}
				});
		},
		
		handleErrorResponse(error) {
			if (error.errors) {
				for (let code in error.errors) {
					let oneError = ''
					let ErrorTitle = 'Error';
					
					if (code && code.toString() !== 'undefined') {
						ErrorTitle += `: ${code}`
						oneError = error.errors[code].message.toString();
					} else {
						oneError = error.errors[code].message.toString();
					}
					this.pushMessage({
						'type': 'error',
						'title': ErrorTitle,
						'text': oneError
					});
				}
				this.scrollToTop();
			}
		}
		
	}
}
